.btn {
  /* safari */
  &:focus {
    outline: 0 !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* end of safari */
  &.primary {
    color: transparent !important;
    @apply bg-transparent  
    text-white 
    border-white;

    &.light {
      @apply hover:bg-white  
    hover:text-primary;

      svg {
        @apply stroke-white;
      }
    }
    &.dark {
      @apply text-primary 
    border-primary 
    hover:bg-primary  
    hover:text-white;

      svg {
        @apply stroke-primary;
      }
    }
    &.dark:active {
      svg {
        @apply !stroke-primary;
      }
    }
  }

  &.secondary {
    color: transparent !important;
    @apply bg-white 
      bg-opacity-[0.2] 
      backdrop-blur-[2px]
      border-none;

    &.light {
      @apply text-white
      hover:text-primary
      hover:[background:rgba(255,255,255,0.90)];

      svg {
        @apply stroke-white;
      }
    }

    &.dark {
      @apply text-primary 
      [background:rgba(8,27,54,0.06)]
      
      hover:bg-primary hover:bg-opacity-[0.9]  
      hover:text-white;

      svg {
        @apply stroke-primary;
      }
    }
    &.dark:active {
      svg {
        @apply !stroke-primary;
      }
    }
  }

  &.primary.light::before,
  &.primary.light::after,
  &.primary.dark::before,
  &.primary.dark::after,
  &.secondary.light::before,
  &.secondary.light::after,
  &.secondary.dark::before,
  &.secondary.dark::after {
    content: attr(databefore);
    @apply absolute font-cera text-[14px] leading-normal w-full h-full flex justify-center items-center transition-all duration-1000 left-0 top-0;
  }

  &.primary.light::before,
  &.secondary.light::before {
    @apply text-white group-hover:bg-transparent group-hover:-translate-y-full;
  }

  &.primary.light::after,
  &.secondary.light::after {
    @apply text-primary translate-y-full
    group-hover:bg-transparent 
    group-hover:translate-y-0;
  }

  &.primary.dark::before,
  &.secondary.dark::before {
    @apply text-primary 
    group-hover:bg-transparent 
    group-hover:-translate-y-full;
  }

  &.primary.dark::after,
  &.secondary.dark::after {
    @apply text-white translate-y-full 
    group-hover:bg-transparent 
    group-hover:translate-y-0;
  }

  /* &.secondary.light::before,
  &.secondary.light::after,
  &.secondary.dark::before,
  &.secondary.dark::after {
    content: attr(databefore);
    @apply absolute font-cera text-[14px] leading-normal w-full h-full flex justify-center items-center transition-all duration-1000 left-0 top-0;
  } */

  /* &.secondary.light::before {
    @apply text-white group-hover:bg-transparent group-hover:-translate-y-full;
  } */

  /* &.secondary.light::after {
    @apply text-primary translate-y-full
    group-hover:bg-transparent 
    group-hover:translate-y-0;
  } */

  /* &.secondary.dark::before {
    @apply text-primary 
    group-hover:bg-transparent 
    group-hover:-translate-y-full;
  } */
  /* 
  &.secondary.dark::after {
    @apply text-white translate-y-full 
    group-hover:bg-transparent 
    group-hover:translate-y-0;
  } */

  &.primary.light:hover svg {
    @apply stroke-primary;
  }
  &.primary.dark:hover svg {
    @apply stroke-white;
  }
  &.secondary.light:hover svg {
    @apply stroke-primary;
  }
  &.secondary.dark:hover svg {
    @apply stroke-white;
  }

  &.primary.light[disabled] {
    @apply text-primary bg-white;
  }
  &.primary.dark[disabled] {
    @apply !text-primary/30 bg-transparent after:!text-black before:!text-black border-black/10;
    svg {
      @apply stroke-primary;
    }
  }
  &.secondary.light[disabled] {
    @apply text-white bg-black bg-opacity-20 backdrop-blur-sm;
    svg {
      @apply stroke-white;
    }
  }
  &.secondary.dark[disabled] {
    @apply text-primary bg-black bg-opacity-20 backdrop-blur-sm;
    svg {
      @apply stroke-primary;
    }
  }

  &.tertiary {
    @apply bg-transparent text-transparent border-none px-0 leading-normal;
    background-color: transparent !important;
    color: transparent !important;
    height: fit-content !important;
    &.tertiary-link {
      @apply inline-block pb-[19px] mt-[-6px];
    }
  }

  &.tertiary.light::before,
  &.tertiary.light::after,
  &.tertiary.dark::before,
  &.tertiary.dark::after {
    content: attr(databefore);
    @apply absolute font-cera text-[14px] leading-normal w-full h-full flex justify-center items-center transition-all duration-1000 left-0 top-0;
  }

  &.tertiary.light::before {
    @apply text-white group-hover:bg-transparent group-hover:-translate-y-full;
  }

  &.tertiary.light::after {
    @apply text-white translate-y-full
    group-hover:bg-transparent 
    group-hover:translate-y-0;
  }

  &.tertiary.dark::before {
    @apply text-primary 
    group-hover:bg-transparent 
    group-hover:-translate-y-full;
  }

  &.tertiary.dark::after {
    @apply text-primary translate-y-full 
    group-hover:bg-transparent 
    group-hover:translate-y-0;
  }

  /* &.tertiary.light:hover:before,
  &.tertiary.dark:hover:before {
    @apply bg-transparent -translate-y-full;
  } */

  /* &.tertiary.light:hover::after,
  &.tertiary.dark:hover::after {
    @apply bg-transparent translate-y-0;
  } */

  /* &.tertiary.light {
    .module-link {
      @apply relative;
    }

    .module-link::after {
      content: '';
      @apply absolute w-full h-[1px] left-0 top-[115%] bg-white translate-x-0 transition-all duration-1000;
    }
  }

  &.tertiary.dark {
    .module-link {
      @apply relative;
    }

    .module-link::after {
      content: '';
      @apply absolute w-full h-[1px] left-0 top-[115%] bg-primary translate-x-0 transition-all duration-1000;
    }
  } */

  /* &.tertiary:hover {
    .module-link::after {
      @apply absolute translate-x-[120%] opacity-0;
    }
  } */
}
.pagination-group {
  @apply relative overflow-hidden hidden md:flex justify-center items-center pt-[2px] font-ivy-ora font-normal text-xs leading-8 w-[48px] h-[48px] rounded-full;
  .pagination-btn {
    @apply text-transparent;
  }
  .pagination-btn:before,
  .pagination-btn:after {
    content: attr(databefore);
    @apply absolute font-cera text-[14px] leading-normal w-full h-full flex justify-center items-center transition-all duration-1000 left-0 top-[-2px];
    &.dark {
      @apply text-primary;
    }
    &.light {
      @apply text-white;
    }
  }
  .pagination-btn:after {
    @apply translate-y-full;
  }
  &.selected {
    @apply bg-[#FFBA34];
  }
  &:not(.selected) {
    .pagination-btn:hover:before {
      @apply bg-transparent -translate-y-full;
    }
    .pagination-btn:hover:after {
      @apply bg-transparent translate-y-0;
    }
  }
}
.large {
  @apply w-12 h-12 p-[15px];
}

.small {
  @apply w-10 h-10 p-[11px];
}

/* 
.btn.primary {
  @apply text-white  border-0 duration-200 [&_svg]:stroke-white 
  hover:border hover:bg-transparent 
   active:text-black 
   disabled:text-white;
} 
*/

/* secondary button */
/* .btn.secondary {
  @apply border border-white duration-200 [&_svg]:stroke-black text-white
    hover:border-transparent 
   active:text-white 
   disabled:text-white  [&_svg]:disabled:stroke-white;
} 

.btn.secondary.small {
  @apply h-10;
}
*/

/* text button */
/* .btn.text {
  @apply duration-200 [&_svg]:stroke-black 
  
   active:text-white 
  disabled:opacity-25;
} */

/* link button */
/* .btn.link {
  @apply relative px-0 pb-0 duration-200 [&_svg]:stroke-black [&_svg]:duration-200
  
  active:opacity-25 
  disabled:opacity-25;
}

.btn.link::after {
  @apply absolute content-[''] w-full h-[1px] bottom-[1px] left-0 bg-black;
}

.btn.link:hover::after {
  @apply duration-200;
}

.btn.link:active::after {
  @apply opacity-25 duration-200;
}

.btn.link:hover svg {
  @apply duration-200;
} */
